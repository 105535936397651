import React from "react";
import { getBgColor } from "../../../utils/getBgColor";

const TableRowDutys = ({ user, array }) => {
  const color = getBgColor(user.role);

  return (
    <tr style={{ background: color }}>
      <th>{user.nameRu}</th>
      {array.map((day, i) => (
        <td key={i}>{(day === "-" ? "-" : +day).toLocaleString()}</td>
      ))}
      <th>
        {array
          .reduce((acc, val) => (+val > 0 ? acc + +val : acc), 0)
          .toLocaleString()}
      </th>
    </tr>
  );
};

export default TableRowDutys;
