import { doc, setDoc } from "firebase/firestore";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { db } from "../../../firebase";

const DutyRow = ({ user, sending, onStop }) => {
  const [count, setCount] = useState("");

  const handleSend = async () => {
    if (+count > 0) {
      await setDoc(doc(db, "users", user.name), {
        ...user,
        messageMoney: +count,
      }).then(() => {
        setCount("");
        onStop();
      });
    } else {
      onStop();
    }
  };

  useEffect(() => {
    if (sending) {
      handleSend();
    }
  }, [sending]);

  return (
    <div>
      <span>{user.nameRu}</span>
      <input
        placeholder="Деньги"
        value={count}
        onChange={e => setCount(e.target.value)}
      />
    </div>
  );
};

export default DutyRow;
