import { Route, Routes } from "react-router-dom";
import Login from "./pages/auth/Login";
import { getAuth } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import AdminLogin from "./pages/auth/AdminLogin";
import AdminDashborad from "./pages/admin/AdminDashborad";
import Today from "./pages/admin/toDay/Today";
import History from "./pages/admin/history/History";
import Notifications from "./pages/admin/notifications/Notifications";
import Loading from "./components/Loading";
import Dutys from "./pages/admin/duty/Dutys";

function App() {
  const auth = getAuth();

  const [user, loading] = useAuthState(auth);
  if (loading) return <Loading />;

  return user ? (
    <Routes>
      <Route path="/" element={<AdminDashborad />} />
      <Route path="/today" element={<Today />} />
      <Route path="/duty" element={<Dutys />} />
      <Route path="/history" element={<History />} />
      <Route path="/notifications" element={<Notifications />} />
    </Routes>
  ) : (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/admin/login" element={<AdminLogin />} />
    </Routes>
  );
}

export default App;
