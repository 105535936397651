import { doc, setDoc } from "firebase/firestore";
import React, { useState } from "react";
import { db } from "../../firebase";

const NewPassword = ({ user }) => {
  const [newPassword, setNewPassword] = useState("");
  const [status, setStatus] = useState(false);

  const handleSavePassword = async () => {
    if (newPassword) {
      await setDoc(doc(db, "users", user.name), {
        ...user,
        password: newPassword,
      }).then(() => {
        setNewPassword("");
        setStatus(true);
        setTimeout(() => {
          setStatus(false);
        }, 3000);
      });
    }
  };
  return (
    <div className="worker-card">
      <h1>Изменить Пароль</h1>
      <div className="columns set-pass">
        <input
          type="text"
          placeholder="Новый пароль"
          value={newPassword}
          onChange={e => setNewPassword(e.target.value)}
        />
        <button
          style={{
            background: status ? "rgb(255, 196, 47)" : " rgb(0, 163, 87)",
          }}
          onClick={handleSavePassword}
        >
          {status ? "Сохранено" : "Сохранить"}
        </button>
      </div>
    </div>
  );
};

export default NewPassword;
