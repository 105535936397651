import { collection, getDocs, query, where } from "firebase/firestore";
import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import WorkPage from "../work/WorkPage";
import { useDispatch, useSelector } from "react-redux";
import "./Login.scss";
import { setUser } from "../../app/userSlice";

const Login = () => {
  const [password, setPassword] = useState("");
  const { user } = useSelector(state => state.user);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleAdmin = () => navigate("/admin/login");

  const handleGetData = async () => {

    const q = query(collection(db, "users"), where("password", "==", password));

    const a = await getDocs(q)

    if (a.size) {
      dispatch(setUser(a.docs[0].data()));
    } else {
      alert("Неверный логин или пароль");
    }
  };

  
    return !user ? (
      <div className="login-worker">
        <input
          className="show-pass-input"
          value={password}
          onChange={e => setPassword(e.target.value)}
          type="text"
          placeholder="Введите пароль"
        />
        <button className="sign-in" onClick={handleGetData}>
          Войти
        </button>
        <button className="admin" onClick={handleAdmin}>
          Администратор
        </button>
      </div>
    ) : (
      <WorkPage user={user} />
    );
};

export default Login;
