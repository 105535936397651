import { doc, setDoc } from "firebase/firestore";
import React from "react";
import { useState } from "react";
import { db } from "../../firebase";

const GetMoney = ({ user }) => {
  const [count, setCount] = useState("");
  const [status, setStatus] = useState(false);

  const handleSend = async () => {
    await setDoc(doc(db, "users", user.name), {
      ...user,
      messageMoney: +count,
    }).then(() => {
      setCount("");
      setStatus(true);
    });
  };

  return (
    <div style={{paddingTop: 35}} className="columns">
      <h2>Прасить аванс</h2>
      {user.messageMoney ? (
        <p style={{ color: "red" }}>
          Ваш заявка в сумме{" "}
          <span style={{ color: "black" }}>
            {user.messageMoney.toLocaleString()} тг
          </span>{" "}
          на рассмотрении !
        </p>
      ) : (
        <div className="form-get-money">
          {!status ? (
            <>
              {" "}
              <input placeholder="0 тг." value={count} onChange={e => setCount(e.target.value)} />
              <button onClick={handleSend}>Отправить заявку</button>
            </>
          ) : (
            <p style={{ color: "red" }}>Ваш заявка отправлено !</p>
          )}
        </div>
      )}
    </div>
  );
};

export default GetMoney;
