import { collection, query } from "firebase/firestore";
import React from "react";
import { useState } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import GoBack from "../../../components/back/GoBack";
import Loading from "../../../components/Loading";
import { db } from "../../../firebase";
import { optionSort } from "../../../utils/options";
import TableRowDutys from "./TableRowDutys";
import TableRowUser from "./TableRowUser";

const days31 = Array.from({ length: 31 }).map((_, i) => i + 1);

// const months = [
//   "Январь",
//   "Февраль",
//   "Март",
//   "Апрель",
//   "Май",
//   "Июнь",
//   "Июль",
//   "Август",
//   "Сентябрь",
//   "Октябрь",
//   "Ноябрь",
//   "Декабрь",
// ];

const History = () => {
  const [users, loading] = useCollectionData(query(collection(db, "users")));
  const [next, setNext] = useState(true);
  const array31 = Array.from({ length: 31 }, () => "-");

  if (loading) return <Loading />;
  
  return (
    <div>
      <GoBack />
      <h1 style={{ textAlign: "center", padding: 20 }}>Замесы</h1>

      <button className="next-month" onClick={() => setNext(!next)}>
        {next ? "Прошлый месяц" : "Данный месяц"}
      </button>

      <table border={1} style={{ margin: "0 15px" }}>
        <tbody>
          <tr>
            <th>Имя</th>
            {days31.map(dd => (
              <td key={dd}>{dd}</td>
            ))}
            <th>Замесы</th>
            <th>Зарплата</th>
          </tr>
          {users.sort(optionSort).map(user => (
            <TableRowUser
              key={user.name}
              array={next ? user.days : user?.lastMonthDays}
              user={user}
            />
          ))}
          <tr style={{ background: "orange" }}>
            <th>Замес</th>
            {array31
              .map(
                (day, i) =>
                  users.reduce((acc, user) => {
                    if (+user.days[i] > 0) return +user.days[i] * 100 + acc;
                    return acc;
                  }, 0) /
                  100 /
                  4
              )
              .map((day, i) => (
                <td key={i}>{day}</td>
              ))}
          </tr>
        </tbody>
      </table>

      <h1 style={{ textAlign: "center", padding: 20 }}>Долги</h1>

      <table border={1} style={{}}>
        <tbody>
          <tr>
            <th>Имя</th>
            {days31.map(dd => (
              <td key={dd}>{dd}</td>
            ))}
            <th>Общее</th>
          </tr>
          {users.sort(optionSort).map(user => (
            <TableRowDutys
              key={user.name}
              array={next ? user.dutys : user.lastMonthDutys}
              user={user}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default History;
