import { collection, query } from "firebase/firestore";
import React from "react";
import { useState } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import GoBack from "../../../components/back/GoBack";
import Loading from "../../../components/Loading";
import { db } from "../../../firebase";
import DutyRow from "./DutyRow";
import "./Dutys.scss";

const Dutys = () => {
  const [sending, setSending] = useState(false);
  const [users, loading] = useCollectionData(query(collection(db, "users")));

  const handleSend = () => setSending(true);

  if (loading) return <Loading />;

  return (
    <>
      <GoBack />
      <div className="dutys-div">

        <div className="dutys-users-row">
        {users.map(user => (
          <DutyRow
            key={user.name}
            user={user}
            sending={sending}
            onStop={() => setSending(false)}
          />
        ))}
        </div>

        <div className="button-send-all">
          <button onClick={handleSend}>Отправить заявку</button>
        </div>
      </div>
    </>
  );
};

export default Dutys;
