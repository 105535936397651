import { doc, setDoc } from "firebase/firestore";
import { db } from "./firebase";

const refreshAll = users => {
 
  users.forEach(async user => {
    await setDoc(doc(db, "users", user.name), {
      ...user,
      daysCount: user.days.reduce(
        (acc, val) => (+val > 0 ? +val + acc : acc),
        0
      ),
      lastDaysCount: user.lastMonthDays.reduce(
        (acc, val) => (+val > 0 ? +val + acc : acc),
        0
      ),
      dutysCount: user.dutys.reduce(
        (acc, val) => (+val > 0 ? +val + acc : acc),
        0
      ),
      lastDutysCount: user.lastMonthDutys.reduce(
        (acc, val) => (+val > 0 ? +val + acc : acc),
        0
      ),
    });
  });
};

export default refreshAll;
