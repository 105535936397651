import React from "react";
import { getAuth, signOut } from "firebase/auth";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { db } from "../../firebase";
import "./Dashboard.scss";

const AdminDashborad = () => {
  const auth = getAuth();
  const q = query(collection(db, "users"), where("messages", "!=", []));

  const [messages, setMessages] = useState([]);

  const notifications = messages.length;

  useEffect(() => {
    const get = async () => {
      const d = await getDocs(q);

      d.forEach(msg => setMessages([...msg.data().messages]));
    };
    get();
  }, []);

  const handleLogOut = () => signOut(auth);

  return (
    <>
      <div>
        <ul className="dashboard-ul">
          <Link to="/notifications">
            Уведомленые{" "}
            <span style={{ color: "red" }}>
              {notifications && `+ ${notifications}`}
            </span>
          </Link>
          <Link to="/today">Добавить замесы</Link>
          <Link to="/duty">Заявка денег</Link>
          <Link to="/history">История</Link>
        </ul>

        <button className="log-out" onClick={handleLogOut}>
          Выйти
        </button>
      </div>
    </>
  );
};

export default AdminDashborad;
