import {
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useNavigate } from "react-router-dom";
import GoBack from "../../../components/back/GoBack";
import { db } from "../../../firebase";

const Notifications = () => {
  const navigate = useNavigate();
  const q = query(collection(db, "users"), where("messages", "!=", []));
  const [users] = useCollectionData(query(collection(db, "users")));

  const [messages, setMessages] = useState([]);

  const handleDelete = async () => {
    const a = async user => {
      await setDoc(doc(db, "users", user.name), {
        ...user,
        messages: [],
      }).then(() => navigate(-1));
    };
    users.forEach(user => {
      a(user);
    });
  };

  useEffect(() => {
    const get = async () => {
      const d = await getDocs(q);

      d.forEach(msg => setMessages([...messages, ...msg.data().messages]));
    };
    get();
  }, []);
  return (
    <div>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <GoBack />
        <button
          style={{
            margin: 15,
            border: 0,
            borderRadius: 10,
            background: "orange",
            padding: "10px 25px",
            fontSize: 18
          }}
          onClick={handleDelete}
        >
          Удалить все
        </button>
      </div>

      {messages.map((msg, i) => (
        <p style={{ margin: 10 }} key={msg + i}>
          {msg}
        </p>
      ))}
    </div>
  );
};

export default Notifications;
