import { doc, setDoc } from "firebase/firestore";
import React from "react";
import { useState } from "react";
import { db } from "../../../firebase";
import { getBgColor } from "../../../utils/getBgColor";

const TodayItem = ({ user, days, refresh, onStop }) => {
  const [count, setCount] = useState("");
  const [status, setStatus] = useState("Добавить");

  const bgColor = getBgColor(user.role);

  const handleAddWork = async () => {
    if (+days > 0 && +days < 32 && (+count >= 0 || count === "-")) {
      const value = +count === 0 ? "-" : count;
      await setDoc(doc(db, "users", user.name), {
        ...user,
        days: user.days.map((thisDay, i) =>
          i === +days - 1 ? value : thisDay
        ),
      }).then(() => {
        setCount("");
        setStatus("Добавлено");
        setTimeout(() => setStatus("Добавить"), 2000);
        onStop();
      });
    }
  };

  refresh ? count ? handleAddWork() : <></> : <></>;

  return (
    <div className="user-adding">
      <span className="name" style={{ background: bgColor }}>
        {user.nameRu}
      </span>

      <div>{days}</div>
      <input
        type="text"
        placeholder="Замес"
        value={count}
        onChange={e => setCount(e.target.value)}
      />
      <button
        style={{
          background: status === "Добавлено" ? "rgb(0, 165, 75)" : "",
        }}
        onClick={handleAddWork}
      >
        {status}
      </button>
    </div>
  );
};

export default TodayItem;
