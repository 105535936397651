import React, { useEffect } from "react";
import { collection, query } from "firebase/firestore";
import { db } from "../../../firebase";
import { useCollectionData } from "react-firebase-hooks/firestore";
import TodayItem from "./TodayItem";
import GoBack from "../../../components/back/GoBack";
import "./Today.scss";
import { useState } from "react";
import { optionSort } from "../../../utils/options";
import refreshAll from "../../../refreshAll";
import Loading from "../../../components/Loading";

const Today = () => {
  const [days, setDays] = useState("");
  const [users, loading] = useCollectionData(query(collection(db, "users")));
  const [adding, setAdding] = useState(false);

  const saturday = new Date(
    `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${days}`
  ).getDay();

  useEffect(() => {
    if (users?.length) {
      refreshAll(users);
    }
  }, [users]);

  if (loading) return <Loading />;

  const handleSetAll = () => setAdding(true);
  const handleStop = () => {
    setDays("");
    setAdding(false);
  };

  return (
    <div className="users-list">
      <GoBack />
      {+saturday === 6 && +days? (
        <p style={{ color: "red", textAlign: "center" }}>
          {days}-ое число разве не суббота?
        </p>
      ) : null}
      <div className="inputs-days">
        <input
          placeholder="День"
          value={days}
          onChange={e => setDays(e.target.value)}
        />
      </div>

      {users?.sort(optionSort).map(user => (
        <TodayItem
          key={user.name}
          user={user}
          days={days}
          refresh={adding}
          onStop={handleStop}
        />
      ))}
      <div className="user-adding">
        <button onClick={handleSetAll}>Добавить все</button>
      </div>
    </div>
  );
};

export default Today;
