import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const AdminLogin = () => {
  const auth = getAuth();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSignIn = async () => {
    if (email && password) {
      signInWithEmailAndPassword(auth, email, password)
        .then(() => navigate("/"))
        .catch(err => alert('Неверный логин или пароль!'));
    }
  };

  const handleWorker = () => navigate(-1);
  return (
    <div className="login-worker">
      <input
        type="text"
        placeholder="Логин"
        value={email}
        onChange={e => setEmail(e.target.value)}
      />

      <input
        type="password"
        placeholder="Пароль"
        value={password}
        onChange={e => setPassword(e.target.value)}
      />

      <button className="sign-in" onClick={handleSignIn}>
        Войти
      </button>
      <button className="admin" onClick={handleWorker}>
        Назад
      </button>
    </div>
  );
};

export default AdminLogin;
