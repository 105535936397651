import React from "react";
import { getBgColor } from "../../../utils/getBgColor";

const TableRowUser = ({ user, array }) => {
  const count = array.reduce((acc, val) => (+val > 0 ? +val + acc : acc), 0);

  const color = getBgColor(user.role);

  return (
    <tr style={{ background: color }}>
      <th>{user.nameRu}</th>
      {array.map((day, i) => (
        <td key={i}>{day}</td>
      ))}

      <th>{count}</th>
      <th>
        {(
          count * user.money +
          ((count * user.money) / 100) * user.perecent
        ).toLocaleString()}
      </th>
    </tr>
  );
};

export default TableRowUser;
