import React from "react";
import { useState } from "react";
import GetMoney from "./GetMoney";
import LogOut from "./LogOut";
import NewPassword from "./NewPassword";
import UserCard from "./UserCard";
import "./WorkPage.scss";

const WorkPage = ({ user: currentUser }) => {
  const [next, setNext] = useState(true);

  const handleNext = () => setNext(!next);

  return (
    <>
      <div className="worker-card">
        <GetMoney user={currentUser} />
        <LogOut />
      </div>

      <UserCard
        title="Замесы"
        name={currentUser.name}
        nameRu={currentUser.nameRu}
        array={next ? currentUser.days : currentUser.lastMonthDays}
        user={currentUser}
        next={next}
        handleNext={handleNext}
      />
      <UserCard
        title="Долги"
        name={currentUser.name}
        nameRu={currentUser.nameRu}
        array={next ? currentUser.dutys : currentUser?.lastMonthDutys}
        user={currentUser}
        next={next}
        handleNext={handleNext}
      />
      <NewPassword user={currentUser} />
    </>
  );
};

export default WorkPage;
