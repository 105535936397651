import React from "react";
import Set from "./Set";

const UserCard = ({ title, nameRu, array, name, user, handleNext, next }) => {
  const arrayReduce = array.reduce(
    (acc, val) => (+val > 0 ? +val + acc : acc),
    0
  );
  return (
    <div style={{ background: next ? "" : "white" }} className="worker-card">
      {user.lastMonthDays ? (
        <div
          style={{
            display: "flex",
            justifyContent: next ? "flex-start" : "flex-end",
          }}
        >
          <button
            style={{ background: next ? "" : "orange" }}
            className="last-month-btn"
            onClick={handleNext}
          >
            {next ? "Прошлый месяц" : "Следующий месяц"}
          </button>
        </div>
      ) : null}

      <h1>{next ? "Данный месяц" : "Прошлый месяц"}</h1>
      <h1>{nameRu}</h1>
      <div className="content">
        <div className="columns">
          <h2>День</h2>
          {array?.map((d, i) => (
            <h3 key={i}>{i + 1}</h3>
          ))}
        </div>
        <div className="columns">
          <h2>{title}</h2>
          {array?.map((day, i) => (
            <h3 key={i}>{day}</h3>
          ))}
        </div>
        {next ? (
          <div className="columns">
            <h2>Изменить</h2>

            {array?.map((day, i) => (
              <Set
                key={i}
                name={name}
                day={i + 1}
                user={user}
                message={`замес должно бить`}
              />
            ))}
          </div>
        ) : null}
      </div>
      <div className="columns info">
        <h2>Общее</h2>
        <h3>
          {title} : {arrayReduce} {title === "Долги" && " тг."}
        </h3>
        <h3>
          <span style={{ color: "blue" }}>
            {title === "Замесы"
              ? (arrayReduce * user.money).toLocaleString() + " тг."
              : ""}{" "}
          </span>
        </h3>
      </div>
    </div>
  );
};

export default UserCard;
