import React from "react";
import { useNavigate } from "react-router-dom";

const buttonStyles = {
  background: "rgb(0, 200, 238)",
  border: 0,
  borderRadius: "10px",
  padding: "8px 15px",
  margin: 5,
  fontWeight: "bold"
};

const GoBack = () => {
  const navigate = useNavigate();
  return (
    <div>
      <button style={buttonStyles} onClick={() => navigate(-1)}>
        Назад
      </button>
    </div>
  );
};

export default GoBack;
