
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyC6DsWyKrrwYzBYWGDolf7yOjA0lwJiVqQ",
  authDomain: "vahe-33906.firebaseapp.com",
  projectId: "vahe-33906",
  storageBucket: "vahe-33906.appspot.com",
  messagingSenderId: "1038406418400",
  appId: "1:1038406418400:web:9e3f4b52125c4e2be3f585"
};


const app = initializeApp(firebaseConfig);

export const db = getFirestore(app)