import React from 'react';
import "./Loading.scss"

const Loading = () => {
    return (
        <div className='loading-div'>
            <h2>Загрузка...</h2>
        </div>
    );
};

export default Loading;