import { doc, setDoc } from "firebase/firestore";
import React, { useState } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { IconContext } from "react-icons/lib";
import { db } from "../../firebase";
import { useDispatch } from "react-redux";
import { setUser } from "../../app/userSlice";

const Set = ({ name, day, user, message }) => {
  const dispatch = useDispatch();
  const [setting, setSetting] = useState(true);
  const [value, setValue] = useState("");
  const [status, setStatus] = useState(true);

  const handleSet = async () => {
    if (value && (+value === 0 || +value > 0)) {
      const newMessages = [
        ...user.messages,
        `${name} подтверждает что ${day}-ое число ${value} ${message}`,
      ];
      if (+value >= 0) {
        setSetting(true);
        await setDoc(doc(db, "users", name), {
          ...user,
          messages: newMessages,
        }).then(() => dispatch(setUser({ ...user, messages: newMessages })));
        setValue("");
      } else {
        setValue("");
        setSetting(true);
        setStatus(true)
      }
    }else{
      setValue("");
      setStatus(true)
      setSetting(true);
    }
  };

  return setting ? (
    <h3>
      {" "}
      <button
        style={{ color: status ? "" : "white" }}
        onClick={() => {
          setSetting(!setting);
          setStatus(false);
          setTimeout(() => {
            setStatus(true);
          }, 100000);
        }}
        className="set"
      >
        {status ? "Исправить" : "В оброботке"}
      </button>
    </h3>
  ) : (
    <div className="setInput">
      <input value={value} onChange={e => setValue(e.target.value)} />
      <span onClick={handleSet}>
        {" "}
        <IconContext.Provider value={{ size: 24 }}>
          <AiOutlineCheck />
        </IconContext.Provider>
      </span>
    </div>
  );
};

export default Set;
