import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { removeUser } from "../../app/userSlice";

const LogOut = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogOut = () => {
    dispatch(removeUser());
    navigate("/");
  };

  return (
    <button className="logout" onClick={handleLogOut}>
      Выйти
    </button>
  );
};

export default LogOut;
